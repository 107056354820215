const NotifiIconDark = ({ height = '40', width = '340' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 442 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.5626 60.3414C80.7142 61.1673 77.7071 61.6095 74.5986 61.6095C56.558 61.6095 41.9332 46.7172 41.9332 28.3467C41.9332 25.0685 42.3989 21.9011 43.2666 18.9093H9.41978C4.21738 18.9093 0 23.2038 0 28.5014V94.4082C0 99.7057 4.21738 104 9.41978 104H74.1428C79.3452 104 83.5626 99.7057 83.5626 94.4082V60.3414Z"
        fill="#262949"
      />
      <path
        d="M102.132 23.6364C102.132 36.6904 91.7394 47.2727 78.9198 47.2727C66.1003 47.2727 55.708 36.6904 55.708 23.6364C55.708 10.5824 66.1003 0 78.9198 0C91.7394 0 102.132 10.5824 102.132 23.6364Z"
        fill="url(#paint0_linear_2302_16417)"
      />
      <path
        d="M201.199 67.4007V103.285H215.834V67.4007C215.834 45.3456 204.594 30.9204 186.799 30.9204C178.135 30.9204 170.408 36.1659 167.833 43.4381V31.7549H153.198V103.285H167.833V67.5199C167.833 54.8829 174.74 45.8225 184.223 45.8225C194.526 45.8225 201.199 54.2868 201.199 67.4007Z"
        fill="#262949"
      />
      <path
        d="M225.615 67.4007C225.615 88.1444 240.717 104 260.152 104C279.586 104 294.572 88.1444 294.572 67.4007C294.572 46.7762 279.586 30.9204 260.152 30.9204C240.717 30.9204 225.615 46.7762 225.615 67.4007ZM260.152 45.8225C271.391 45.8225 279.938 55.1214 279.938 67.4007C279.938 79.7992 271.391 89.0981 260.152 89.0981C248.913 89.0981 240.249 79.7992 240.249 67.4007C240.249 55.1214 248.913 45.8225 260.152 45.8225Z"
        fill="#262949"
      />
      <path
        d="M315.343 16.6143L311.831 31.7549H301.411V45.8225H311.831V78.7262C311.831 96.2511 318.855 103.285 335.597 103.285H341.685V88.502H337.002C329.743 88.502 326.465 85.2832 326.465 77.8917V45.8225H341.685V31.7549H326.465V16.6143H315.343Z"
        fill="#262949"
      />
      <path
        d="M366.497 103.285V31.7549H351.863V103.285H366.497Z"
        fill="#262949"
      />
      <path
        d="M441.469 103.285V31.7549H401.195V26.5094C401.195 19.1179 404.474 15.7798 411.732 15.7798H416.415V1.11615H410.327C393.586 1.11615 386.561 8.03078 386.561 25.6748V31.7549H376.258V45.8225H386.561V103.285H401.195V45.8225H426.835V103.285H441.469Z"
        fill="#262949"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.486 0C353.35 0 351.863 2.31119 351.863 5.5981V17.547H363.336C367.321 17.547 369.095 16.1265 369.095 12.1206V5.5981C369.095 1.84896 366.665 0 362.933 0H357.486Z"
        fill="#262949"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2302_16417"
          x1="92.9374"
          y1="3.68359"
          x2="69.9874"
          y2="46.9318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7970" />
          <stop offset="1" stopColor="#FEB776" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default NotifiIconDark
