import React from 'react'

type Props = React.SVGAttributes<SVGElement>

export default function CommunityMintIcon(props: Props) {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 13.3337H14.6654V12.0003C14.6654 10.8958 13.7699 10.0003 12.6654 10.0003C12.0283 10.0003 11.4608 10.2982 11.0945 10.7623M11.332 13.3337H4.66536M11.332 13.3337V12.0003C11.332 11.5628 11.2477 11.145 11.0945 10.7623M4.66536 13.3337H1.33203V12.0003C1.33203 10.8958 2.22746 10.0003 3.33203 10.0003C3.9691 10.0003 4.5366 10.2982 4.90286 10.7623M4.66536 13.3337V12.0003C4.66536 11.5628 4.74965 11.145 4.90286 10.7623M4.90286 10.7623C5.39437 9.53434 6.59524 8.66699 7.9987 8.66699C9.40215 8.66699 10.603 9.53434 11.0945 10.7623M9.9987 4.66699C9.9987 5.77156 9.10327 6.66699 7.9987 6.66699C6.89413 6.66699 5.9987 5.77156 5.9987 4.66699C5.9987 3.56242 6.89413 2.66699 7.9987 2.66699C9.10327 2.66699 9.9987 3.56242 9.9987 4.66699ZM13.9987 6.66699C13.9987 7.40337 13.4017 8.00032 12.6654 8.00032C11.929 8.00032 11.332 7.40337 11.332 6.66699C11.332 5.93061 11.929 5.33366 12.6654 5.33366C13.4017 5.33366 13.9987 5.93061 13.9987 6.66699ZM4.66536 6.66699C4.66536 7.40337 4.06841 8.00032 3.33203 8.00032C2.59565 8.00032 1.9987 7.40337 1.9987 6.66699C1.9987 5.93061 2.59565 5.33366 3.33203 5.33366C4.06841 5.33366 4.66536 5.93061 4.66536 6.66699Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
